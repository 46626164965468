import * as React from 'react'

import {
  HorizontalAlignment,
  VerticalAlignment,
} from '@thg-commerce/enterprise-core'
import { ButtonEmphases } from '@thg-commerce/gravity-elements/Button/Button'
import { Picture, PictureProps } from '@thg-commerce/gravity-system'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'

import {
  CardContainer,
  CardCTA,
  CardImageGridItem,
  CardSubtitle,
  CardTextGridItem,
  CardTitle,
  ContainerLink,
} from './styles'
import { CardType, TextProps } from './types'

const PrefetchContainerLink = withPrefetch('href', ContainerLink)

export interface ImageCardProps {
  picture: PictureProps
  link?: string
  cardType: CardType
  verticalAlignment: VerticalAlignment
  displayBorder?: boolean
  noGridGap?: boolean
  contentProps?: {
    title?: TextProps
    subtitle?: TextProps
    horizontalAlignment?: HorizontalAlignment
    cta?: {
      text?: string
      emphasis?: ButtonEmphases
    }
  }
}

export const ImageCard = (props: ImageCardProps) => {
  const enableCTA = Boolean(props.contentProps?.cta?.text?.trim())
  const enableTitle = Boolean(props.contentProps?.title?.text?.trim())
  const enableSubtitle = Boolean(props.contentProps?.subtitle?.text?.trim())

  const imageCard = (
    <CardContainer
      rows={props.cardType === 'TextOver' ? 1 : 2}
      columns={1}
      rowGap={0}
      cardType={props.cardType}
      data-testid={`image-${props.cardType}`}
      noGridGap={props.noGridGap}
    >
      <CardImageGridItem rowStart={1} colStart={1}>
        <Picture {...props.picture} />
      </CardImageGridItem>

      {(enableCTA || enableTitle || enableSubtitle) && (
        <CardTextGridItem
          verticalAlignment={
            props.cardType === 'TextOver' ? props.verticalAlignment : 'top'
          }
          horizontalAlignment={
            props.contentProps?.horizontalAlignment || 'center'
          }
          colStart={1}
          rowStart={props.cardType === 'TextOver' ? 1 : 2}
          cardType={props.cardType}
          displayBorder={
            !props.contentProps?.title && !props.contentProps?.subtitle
              ? false
              : props.displayBorder ?? true
          }
        >
          <React.Fragment>
            {enableTitle && (
              <CardTitle
                textColor={
                  (props.cardType === 'TextOver' &&
                    props.contentProps?.title?.color) ||
                  'dark'
                }
                marginBottom={props.contentProps?.title?.marginBottom}
              >
                {props.contentProps?.title?.text}
              </CardTitle>
            )}
            {enableSubtitle && (
              <CardSubtitle
                renderedAs="p"
                textColor={
                  (props.cardType === 'TextOver' &&
                    props.contentProps?.subtitle?.color) ||
                  'dark'
                }
                marginBottom={props.contentProps?.subtitle?.marginBottom}
                content={props.contentProps?.subtitle?.text}
              />
            )}
            {enableCTA && (
              <CardCTA
                renderedAs={props.link ? undefined : 'a'}
                emphasis={props.contentProps?.cta?.emphasis || 'high'}
                href={props.link}
                horizontalAlignment={props.contentProps?.horizontalAlignment}
              >
                {props.contentProps?.cta?.text}
              </CardCTA>
            )}
          </React.Fragment>
        </CardTextGridItem>
      )}
    </CardContainer>
  )

  return props.link ? (
    <PrefetchContainerLink
      href={props.link}
      aria-label={
        props.contentProps?.title?.text || props.contentProps?.cta?.text
      }
      tabIndex={props.contentProps?.cta?.text ? -1 : 0}
      withFocus={
        !props.contentProps?.cta?.text && !props.contentProps?.title?.text
      }
    >
      {imageCard}
    </PrefetchContainerLink>
  ) : (
    imageCard
  )
}
